import moment from 'moment';

const dataLocalFormatada = {
    name:'dataLocalFormatada',
    execute: (date) => {
       const dataFormatada = date != null ? moment(date).format("DD/MM/YYYY") : '';
       return  dataFormatada
    }
}

export default dataLocalFormatada;
