import moment from 'moment';

const formatarDataHora = {
    name:'formatarDataHora',
    execute: (date) => {
       const dataFormatada = date != null ? moment(date).format("DD/MM/YYYY HH:mm") : '';
       return  dataFormatada
    }
}

export default formatarDataHora;