<template>
  <v-app>
    <v-container fill-height>
      <v-row align="center" justify="center">
        <v-col cols="10" lg="4" class="mx-auto">
          <slot />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'LayoutAutenticacao',
};
</script>

<style>
</style>