<template>
  <nav>
    <v-app-bar class="nav-bar" app>
      <v-app-bar-nav-icon
        @click="navegacao_status = !navegacao_status"
        class="grey--text"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span>BENEFICIÁRIO</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn text color="grey" @click="realizarLogout">
        <span>SAIR</span>
        <v-icon right>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      class="nav-bar"
      width="360"
      v-model="navegacao_status"
      app
      dark
    >
      <v-card class="flexcard py-5" color="secundary" dark height="200px">
        <v-col align-center>
          <v-row class="shrink my-5" justify="center">
            <div class="avatar-wrapper">
              <img class="text-lg-center" :src="logo_cassems" />
            </div>
          </v-row>

          <v-row class="shrink" justify="center">
            <p class="white--text subheading mt-3">{{ usuarioAutenticado }}</p>
          </v-row>
        </v-col>
      </v-card>

      <v-list nav shaped>
        <v-subheader class="font-weight-bold"
          >CONSULTA BENEFICIARIOS - ENVIADOS</v-subheader
        >
        <v-divider class="mb-5"></v-divider>
      </v-list>

      <!-- TODO: ajustar os links da navbar, pois nem todos são sublinks, e alguma das tags utilizadas são do vuetify 1.0 -->
      <v-list>
        <v-list-group
          v-for="item in getLista()"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <v-list-item-group>
            <v-list-item
              class="item-list"
              v-for="item in item.items2"
              :key="item.text"
              link
              :to="item.link"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="item-list-tile">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>

          <template v-slot:activator>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="subItem in getLista().items"
            :key="subItem.title"
            link
            :to="item.link"
          >
            <v-list-item-action>
              <v-icon>{{ subItem.action }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import logo_cassems from "@/assets/logo_cassems.png";
import { pegarUsuarioAutenticado } from "@/auth/AutenticacaoService";

export default {
  data: () => ({
    navegacao_status: false,
    logo_cassems,
    itemSelecioando: 0,
  }),
  computed: {
    usuarioAutenticado() {
      const usuario = pegarUsuarioAutenticado();
      return usuario.login;
    },
  },
  methods: {
    getLista() {
      var lista = [];
      return lista;
    },
    realizarLogout() {
      sessionStorage.removeItem("usuario-token");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-wrapper {
  background: transparent;
}

.flexcard {
  display: flex;
  flex-direction: column;

  border-radius: 0px;
}

.flexcard .v-toolbar {
  flex: 0;
}

.list-item-drawer {
  min-height: 35px;
}

.v-toolbar__items > .v-btn {
  border-radius: 0;
  height: 32% !important;
  max-height: none;
}
</style>
