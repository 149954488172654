import moment from 'moment';

const formatarData = {
    name:'formatarData',
    execute: (date) => {
       const dataFormatada = date?moment(date).format("DD/MM/YYYY"):null;
       return  dataFormatada
    }
}

export default formatarData;