import dataLocalFormatada from './data/dataLocalFormatada.js'
import formatarDataParaMesAno from './data/formatarDataParaMesAno.js'
import formatadorDeData from './formatadorDeData'
import formatarDataHora from './formatadorDeDataHora'
import formatarMoeda from './formatarMoeda.js'
import percentualNulo from './percentualNulo.js';
import formatarCpf from './formatarCpf.js';

const filters = [
    dataLocalFormatada,
    formatarDataParaMesAno,
    formatarMoeda,
    percentualNulo,
    formatadorDeData,
    formatarDataHora,
    formatarCpf
]

export default filters;
