<template>
  <component :is="this.$route.meta.layout || 'div'">
    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
  </component>
</template>

<script>
import NavBar from "@/components/Navbar";

export default {
  name: 'App',

  components: {
    NavBar
  },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">

main{
  background-color: rgb(238, 245, 249);
}

.body {
  display: flex;
  flex-direction: column;
}

@keyframes slide-in {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(-30px);
    opacity: 0;
  }
}

.slide-enter-active {
  animation: slide-in 0.3s ease;
}

.slide-leave-active {
  animation: slide-out 0.3s ease;
}


::-webkit-scrollbar { 
  scrollbar-base-color: #C0C0C0;
  scrollbar-base-color: #C0C0C0;
  scrollbar-3dlight-color: #C0C0C0;
  scrollbar-highlight-color: #C0C0C0;
  scrollbar-track-color: #EBEBEB;
  scrollbar-arrow-color: black;
  scrollbar-shadow-color: #C0C0C0;
  scrollbar-dark-shadow-color: #C0C0C0;
 }

::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}
::-webkit-scrollbar {
    width: 6px;
    background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
    background: #dad7d7;
}
</style>
