import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueToast from 'vue-toast-notification';
import JsonExcel from "vue-json-excel";

import 'vue-toast-notification/dist/theme-sugar.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(VueToast);
Vue.component("downloadExcel", JsonExcel);

//SCSS Principal
import '@/assets/scss/main.scss';

Vue.config.productionTip = false

//Chamando o arquivo de Filtro que vai conter todos os filtros customizaveis
import filtros from './filtros/filtros.js'


//Faz um loop pelos filtros presente no array do arquivo filtros.js e executa o método execute
filtros.forEach( filtro =>{
  Vue.filter(filtro.name,filtro.execute)
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
