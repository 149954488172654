import Vue from "vue";
import VueRouter from "vue-router";

/*Layouts*/
import LayoutAutenticacao from "@/layouts/LayoutAutenticacao";
import LayoutDashboard from "@/layouts/LayoutDashboard";

import {
  estaAutenticado,
  tokenExpirado,
  verificarSeUsuarioPossuiPermissao,
} from "@/auth/AutenticacaoService";

Vue.use(VueRouter);

const Login = () =>
  import(/* webpackChunkName: "login" */ "../views/Login.vue");

const BuscaDeBeneficiarioOdonto = () =>
  import(
    /*webpackChunkName: "BuscaDeBeneficiarioOdonto"*/ "../views/desconto/BuscaDeBeneficiarioOdonto.vue"
  );

const UsuarioSemPermissao = () =>
  import(
    /*webpackChunkName: "BuscaDeBeneficiarioOdonto"*/ "../views/autenticacao/UsuarioSemPermissao.vue"
  );

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { layout: LayoutAutenticacao },
  },
  {
    path: "/",
    name: "BuscaDeBeneficiarioOdonto",
    component: BuscaDeBeneficiarioOdonto,
    meta: {
      layout: LayoutDashboard,
      // requiresAuth: true,
      // permissions: [Role.ATENDIMENTO],
    },
  },
  {
    path: "/usuarioSemPermissao",
    name: "UsuarioSemPermissao",
    component: UsuarioSemPermissao,
    meta: {
      layout: LayoutAutenticacao,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/**
 * Verificar se o usuário está logado, para acessar determinada url (rota),
 * verificando se existe um token salvo, ainda é valido
 */
router.beforeEach((to, from, next) => {
  const permissions = to.meta.permissions;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!estaAutenticado()) {
      sessionStorage.setItem("caminho-requisitado", to.path);

      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else if (tokenExpirado()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else if (!verificarSeUsuarioPossuiPermissao(permissions)) {
      next({
        path: "/usuarioSemPermissao",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
