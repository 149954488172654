
const formatarMoeda = {
    name:'formatarMoeda',
    execute: (valor) =>{
        const dinheiro = valor == null ? '0'.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        return dinheiro;
    }
}

export default formatarMoeda;
