import moment from 'moment';

const formatarDataParaMesAno = {
    name:'formatarDataParaMesAno',
    execute: (date) => {
       const formatarDataParaMesAno = date != null ? moment(date).format("MM/YYYY") : '';
       return  formatarDataParaMesAno
    }
}

export default formatarDataParaMesAno;
