export function autenticacaoHeader(){
    //importa o usuario do sessionStorage
    const usuario = JSON.parse(sessionStorage.getItem('usuario-token'));

    //verifica se existe um usuario e um token
    if (usuario && usuario.token){
        return `Bearer ${usuario.token}`;
    }else {
        return ;
    }
}

export function pegarUsuarioAutenticado(){
     
    if(!estaAutenticado())
        return null;
    
    //importa o usuario do sessionStorage
    const usuario = JSON.parse(sessionStorage.getItem('usuario-token'));
    return usuario;
}

export function estaAutenticado(){
    //importa o usuario do sessionStorage
    const usuario = JSON.parse(sessionStorage.getItem('usuario-token'));
        
    //verifica se existe um usuario e um token
    if (usuario && usuario.token){
        return true;
    }else {
        return false;
    }
}

/*Decodifica usando o Base64*/
export function pegarPayloadToken(token){
    const base64Token = token.split('.')[1];
    const base64 = base64Token.replace(/-/g, '+').replace(/_/g, '/');
    const tokenPayloadJson = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(tokenPayloadJson);
}

export function tokenExpirado(){
    const usuarioToken = JSON.parse(sessionStorage.getItem('usuario-token'));

    if(usuarioToken === null){
        return true;
    }

    const tokenPayload = pegarPayloadToken(usuarioToken.token);
    return (tokenPayload === null || (tokenPayload.exp < Date.now()/1000)) ? true : false; 
}

export function verificarSeUsuarioPossuiPermissao(permissoes) {
    const usuarioToken = JSON.parse(sessionStorage.getItem('usuario-token'));

    if(usuarioToken === null){
        return false;
    }

    const tokenPayload = pegarPayloadToken(usuarioToken.token);
    const roles = tokenPayload.roles;
    return roles.some(v => permissoes.indexOf(v) !== -1)
}